import { Box, Flex, Text, IconButton, Stack, Collapse, Icon, Popover, PopoverTrigger, PopoverContent, useColorModeValue, useDisclosure, useBreakpointValue, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, ChevronRightIcon, PhoneIcon, EmailIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';

const NAV_ITEMS = [
  {
    label: 'Home',
    href: '/'
  },
  {
    label: 'About',
    href: '/about'
  },
  // {
  //   label: 'Services',
  //   href: '/services'
  // },
  {
    label: 'Portfolio',
    href: '/portfolio'
  }
];

export default function Navigation(props) {

  const { isOpen, onToggle } = useDisclosure()

  const handleContactClick = () => {
    if (isOpen) onToggle();
  }

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Box
            to={''}
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}
            onClick={onToggle}
            style={{ 
              display: 'flex', 
              cursor: 'pointer', 
              border: "2px solid white",
              borderRadius: "5px",
              textAlign: "center", 
              padding: "5px 10px",
              backgroundColor: props.themeColor,
              color: "white" 
            }}
            >
            SIT
          </Box>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          {/* <Button onClick={toggleColorMode}>
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button> */}
          <Box onClick={handleContactClick} fontSize={'m'} fontWeight={500} style={{ 
            cursor: 'pointer',
            display: "flex",
            justifyContent: 'center'
          }}>
            <Popover
              placement='right'
              closeOnBlur={true}
            >
              <PopoverTrigger>
                <Text 
                  padding="5px 10px"
                  border="2px solid black"
                  borderRadius="10px"
                  textAlign='center'
                >Contact</Text>
              </PopoverTrigger>
              <PopoverContent p={5}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader style={{ alignSelf: 'center', width: '80%' }}>Contact</PopoverHeader>
                <PopoverBody>
                  <Box display="flex" margin="10px">
                    <IconButton size='sm' icon={<PhoneIcon />} margin="5px"/>
                    <Text margin="10px" as="a" href="tel:9199071192">(919) 907-1192</Text>
                  </Box>
                  <Box display="flex"  margin="10px">
                    <IconButton size='sm' icon={<EmailIcon />} margin="5px"/>
                    <Text margin="10px" as="a" href="mailto:sitinnovates@gmail.com">sitinnovates@gmail.com</Text>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
          
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onToggle={onToggle} />
      </Collapse>
    </Box>
  )
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')
  const navigate = useNavigate();

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                // as="a"
                p={2}
                // href={navItem.href ?? '#'}
                fontSize={'m'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
                onClick={() => {
                  if (navItem.href !== window.location.pathname) navigate(navItem.href)
                }}
                style={{ cursor: 'pointer'}}
                >
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'m'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }) => {

  const navigate = useNavigate();

  return (
    <Box
      // as="a"
      // href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
      onClick={() => {
        if (href !== window.location.pathname) navigate(href)
      }}
      style={{ cursor: 'pointer'}}
      >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'m'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = ({ onToggle }) => {
  return (
    <Stack bg={useColorModeValue('gray.200', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onToggle={onToggle}/>
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, href, onToggle }) => {

  const navigate = useNavigate();

  return (
    <Stack spacing={4} onClick={onToggle}>
      <Box
        py={2}
        // as="a"
        // href={href}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={() => {
          if (href !== window.location.pathname) navigate(href)
        }}
        style={{ cursor: 'pointer'}}
        >
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
      </Box>
    </Stack>
  )
}