import Navigation from "../Navigation"
import Footer from "../Footer"

export const PageWrapper = (props) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navigation themeColor={props.themeColor}/>
            {props.children}
            <Footer themeColor={props.themeColor} />
        </div>
    )
}