

export const TeamCard = ({name, title, img, bio, color}) => {
    return (
        <div style={{
            textAlign: "center",
            padding: "10px",
            backgroundColor: color,
            margin: "10px",
            borderRadius: "10px",
            maxWidth: '600px'
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                <img src={img} alt='' style={{
                    width: "170px",
                    height: "200px",
                    borderRadius: "5px",
                    margin: "10px"
                }} />
                <div style={{
                    margin: "80px 0px",
                    color: "white"
                }}>
                    <h5 style={{ fontSize: "20px", fontWeight: "bold" }}>{name}</h5>
                    <h6>{title}</h6>
                </div>
            </div>
            
            <p style={{
                color: "white",
                fontStyle: "italic",
                padding: "10px",
                margin: "10px",
                fontSize: "15px",
                border: "2px solid white",
                borderRadius: "10px"
            }}>
                {bio}
            </p>
        </div>
    )
}