// 'use client'
import { Box, Container, Stack, Text, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, IconButton } from '@chakra-ui/react'
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';

export default function SmallCentered(props) {

    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/')
    }

    return (
        <Box
            bg={{ base: props.themeColor}}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                spacing={4}
                justify={'space-evenly'}
                align={'center'}
                display={'flex'}
                flexDirection={'row'}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <button style={{
                        border: "2px solid white",
                        borderRadius: "5px",
                        textAlign: "center",
                        backgroundColor: props.themeColor,
                        color: "white",
                        width: "100px",
                        height: "90px",
                        fontSize: "50px",
                    }} onClick={handleLogoClick}>SIT</button>
                </div>
                <div style={{
                    border: "1px solid",
                    height: "100px"
                }}></div>
                <div style={{
                    color: "white"
                }}>
                    <p><strong>Corporate Headquarters: </strong><a href="maps:0,0?q=303 Rondelay Drive, Durham NC, 27703" style={{ textDecorationLine: 'underline', textDecorationColor: 'white', textDecorationThickness: '1px' }}> 303 Rondelay Drive, Durham NC, 27703</a></p>
                    <hr style={{ border: "none"}} />
                    <p><strong>Phone: </strong><a href="tel:9199071192" style={{ textDecorationLine: 'underline', textDecorationColor: 'white', textDecorationThickness: '1px' }}> (919) 907-1192</a></p>
                </div>
                <div style={{
                    border: "1px solid",
                    height: "100px"
                }}></div>
                <div style={{
                    color: "white"
                }}>
                    <Stack direction={'column'} spacing={1} fontWeight={'bold'}>
                        <Box as="a" href={'/'}>
                            Home
                        </Box>
                        <Box as="a" href={'/about'}>
                            About
                        </Box>
                        {/* <Box as="a" href={'/services'}>
                            Services
                        </Box> */}
                        <Box as="a" href={'/portfolio'}>
                            Portfolio
                        </Box>
                        <Box as="button" style={{ fontWeight: '650', color: 'black', marginRight: '5px' }}>
                            <Popover
                            placement='right'
                            closeOnBlur={true}
                            >
                            <PopoverTrigger>
                                <Text style={{ color: 'white', fontWeight: 'bold' }}>Contact</Text>
                            </PopoverTrigger>
                            <PopoverContent p={5}>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Contact</PopoverHeader>
                                <PopoverBody>
                                <Box display="flex" margin="10px">
                                    <IconButton size='sm' icon={<PhoneIcon />} margin="5px"/>
                                    <Text margin="10px" as="a" href="tel:9199071192">(919) 907-1192</Text>
                                </Box>
                                <Box display="flex"  margin="10px">
                                    <IconButton size='sm' icon={<EmailIcon />} margin="5px"/>
                                    <Text margin="10px" as="a" href="mailto:sitinnovates@gmail.com">sitinnovates@gmail.com</Text>
                                </Box>
                                </PopoverBody>
                            </PopoverContent>
                            </Popover>
                        </Box>
                    </Stack>
                </div>
            </Container>
            <Container
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                maxW={'6xl'}
                py={4}
            >
                <Text style={{ textAlign: "center", color: "white" }}>© 2023 SIT Enterprise. All rights reserved</Text>
            </Container>
        </Box>
    )
}