import * as React from 'react'
import { useNavigate } from 'react-router-dom';

import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/react'

export const AboutSIT = (props) => {

    const navigate = useNavigate();
    const [ counter, setCounter ] = React.useState(0)

    const styles = {
        background: {
            color: 'white',
            justifyContent: 'center',
            paddingTop: '8px',
            margin: 'auto',
            // height: '100%',
            overflow: "hidden"
        }
    }

    const handleContactUsClick = () => {
        navigate('/contact')
    }

    const companyInfo = React.useMemo(() => {
       return {
        "Our Story": 
            <>
                <h5 style={{
                    color: "black"
                }}>Founded: Nov 2, 2023</h5>
                <p style={{
                    color: "black"
                }}>At <strong>SIT</strong>, we are more than just a software engineering company; we are innovators, problem solvers, and collaborators. Our journey began on Nov 2023 when a group of passionate and skilled software engineers came together with a shared vision to revolutionize the technology landscape.</p>
            </>,
        "Our Mission": 
            <>
                <p style={{
                    color: "black"
                }}>At the heart of our company is a commitment to providing cutting-edge software solutions that make a difference. We strive to create products and services that not only meet the needs of today but also anticipate the challenges of tomorrow. Our mission is to empower businesses and individuals with technology, allowing them to thrive in an ever-evolving digital world.</p>
            </>,
        "Our Values": 
            <>
                <ul style={{
                    padding: "0px 10px",
                    color: "black"
                }}>
                    <li><strong>Innovation:</strong> We embrace innovation as a driving force behind our success. We constantly explore new technologies and methodologies to stay at the forefront of the industry.</li>
                    <li><strong>Quality:</strong> We take pride in delivering high-quality software that is reliable, secure, and user-friendly. Our commitment to quality is unwavering.</li>
                    <li><strong>Collaboration:</strong> We believe in the power of collaboration. Working closely with our clients, we build strong relationships and ensure that their vision is realized.</li>
                    <li><strong>Integrity:</strong> Our team operates with honesty, transparency, and integrity. We value trust and open communication.</li>
                </ul>
            </>,
        "What We Do": 
            <>
                <h5 style={{
                    color: "black"
                }}><strong>SIT</strong> specializes on a wide range of software engineering services</h5>
                <ul style={{
                    color: "black",
                    padding: "10px"
                }}>
                    <li><strong>Custom Software Development:</strong> We craft bespoke software solutions tailored to the unique needs of your business.</li>
                    <li><strong>Web Application Development:</strong> Our team creates web applications that are responsive, scalable, and user-friendly.</li>
                    <li><strong>Mobile App Development:</strong> We design and develop mobile applications that run seamlessly on various platforms.</li>
                    <li><strong>Cloud Services:</strong> Leveraging cloud technology, we provide efficient and cost-effective solutions for your business.</li>
                    <li><strong>Consulting:</strong> Our experts offer strategic guidance and consulting services to help you make informed technology decisions.</li>
                    <li><strong>Machine Learning and AI:</strong> We harness the power of machine learning and artificial intelligence to deliver smart solutions that learn and adapt.</li>
                </ul>
            </>
        };
    },[]);

    const handleLeftArrowClick = () => {
        if (counter === 0) {
            setCounter(Object.keys(companyInfo).length - 1)
        } 
        else {
            setCounter(counter - 1)
        }
    }

    const handleRightArrowClick = React.useCallback(() => {
        if (counter === (Object.keys(companyInfo).length - 1)) {
            setCounter(0)
        }
        else{
            setCounter(counter + 1)
        }
    },[counter, companyInfo]);

    React.useEffect(() => {
        const intervalId = setInterval(handleRightArrowClick, 5000);
        return () => clearInterval(intervalId);
    }, [counter,handleRightArrowClick])

    return (
        <div style={styles.background}>
            <div style={{
                textAlign: "center",
                borderBottom: "2px solid #882",
                marginBottom: "20px"
            }}>
                <h1 style={{
                    fontSize: "40px",
                    padding: "10px",
                    color: '#882',
                }}>Explore SIT</h1>
            </div>
            <div style={{
                // height: "70vh"
            }}>
                <div style={{
                    // height: "70%",
                    display: "flex",
                    // border: "2px solid green",
                    // backgroundImage: SitImage,
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                    // // backgroundColor: "red"
                }}>
                    <div style={{
                        backgroundColor: props.themeColor,
                        width: "15%"
                    }}>
                        <ArrowLeftIcon 
                            w="100%"
                            h="100%"
                            padding="20%"
                            onClick={handleLeftArrowClick} 
                        />
                    </div>
                    <div style={{
                        margin: "10px",
                        width: "100%"
                    }}>
                        <Text 
                            fontSize='2xl'
                            padding="5px" 
                            color="black" 
                            borderBottom="2px solid black"
                            textAlign="center"
                        >
                            {Object.keys(companyInfo)[counter]}
                        </Text>
                        <div style={{
                            padding: "15px"
                        }}>
                            {companyInfo[Object.keys(companyInfo)[counter]]}
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: props.themeColor,
                        width: "15%",
                    }}>
                        <ArrowRightIcon 
                            w="100%"
                            h="100%"
                            padding="20%"
                            onClick={handleRightArrowClick} 
                        />
                    </div>
                </div>
                <div style={{
                    margin: "20px",
                    height: "20%",
                }}>
                    <p style={{
                        color: "black"
                    }}>Thank you for taking the time to learn about <strong>SIT</strong>. We are excited to embark on this journey with you. Whether you are a potential client, a collaborator, or someone looking to join our team, we look forward to working together to achieve remarkable results.

                    Feel free to <strong onClick={handleContactUsClick} >Contact Us</strong> to discuss how we can assist you in achieving your technology goals.</p>
                </div>
            </div>
        </div> 
    )
}