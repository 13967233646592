import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from "./components/Homepage";
import About from "./components/About/About";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={ <Homepage /> }/>
          <Route path="/about" element={ <About /> }/>
          {/* <Route path="/services" element={ <Services /> }/> */}
          <Route path="/portfolio" element={ <Portfolio /> }/>
          {/* <Route path="/contact" element={ <Contact /> }/> */}
          <Route path=":default" element={ <Homepage /> }/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;