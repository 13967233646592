import MarcusProfPic from '../../images/MarcusProfPic.jpg'
import AugustineProfPic from "../../images/AugustineProfPic.jpg"
import { TeamCard } from './TeamCard'

const teamInfo = [
    { 
        name: "Marcus McCallum",
        title: "Co-Founder",
        img: MarcusProfPic,
        bio: "Marcus McCallum, a seasoned professional, holds a Bachelor's degree in computer science from Bucknell University. He excels in mobile app development, with a specialization in iOS using Swift and a portfolio of published apps. Marcus has also ventured into blockchain technologies, showcasing proficiency in Web3 and Solidity to create cutting-edge decentralized systems. His skill set extends to web development, where he's mastered Node.js, JavaScript, and React, prioritizing user-focused web solutions. As the Chief Technology Officer at One28 and previously as a Lead iOS Developer, Marcus has demonstrated strategic thinking and leadership. His unwavering commitment to staying at the forefront of technology cements his status as a seasoned professional in software development."
    },
    { 
        name: "Augustine Ubah",
        title: "Co-Founder",
        img: AugustineProfPic,
        bio: "Augustine holds a Bachelor's degree in computer science from Bucknell University. His expertise shines in command-line scripting, crafting efficient and powerful scripts. Augustine excels in configuring Azure infrastructure, covering authentication, CI/CD pipelines, and serverless functions. In the realm of web development, he's a master of React.js and TypeScript, prioritizing user experience and functionality. Python is another tool in his arsenal, utilized for data analysis and cutting-edge AI applications. Augustine's recent work at Microsoft showcased his proficiency in these domains. His dedication to staying at the forefront of technology solidifies his reputation as a seasoned professional in software development."
    }
]

export const AboutTeam = (props) => {
    return (
        <div style={{
            overflow: "auto",
            // height: "100%"
        }}>
            <h4 style={{
                color: props.themeColor,
                fontSize: "30px",
                fontWeight: "bold"
            }}>Our Team</h4>
            <p style={{
                color: "black"
            }}>Our success is driven by our team of talented and dedicated software engineers, designers, and project managers. They are the backbone of our company, working tirelessly to turn concepts into reality and solve complex challenges with innovative solutions.</p>
            <div style={{
                margin: "20px",
                display: "flex",
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: "space-evenly",
                borderRadius: "5px",
            }}>
                { 
                    teamInfo.map((member) => (
                        <TeamCard key={member.name} name={member.name} title={member.title} img={member.img} bio={member.bio} color={props.themeColor} />
                    ))
                }
            </div>
        </div>
    )
}