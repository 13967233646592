import { PageWrapper } from "./Wrappers/PageWrapper"
import React from 'react';

const THEMECOLOR = "#822"

function PortfolioItem({ item, index, _length }) {
  const portfolioItemStyles = {
      container: {
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          margin: '12px 21px',
      },
      imageContainer: {
          width: '40%',
          padding: '12px 0 12px 12px',
      },
      image: {
          width: '69%',
          margin: '12px',
          borderRadius: '12px'
      },
      contentContainer: {
          flex: '1',
          padding: '12px 12px 12px 0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
      },
      title: {
          fontSize: '2.1rem',
          color: THEMECOLOR,
          margin: '12px 0',
          fontWeight: '750',
          letterSpacing: '0.5px',
      },
      description: {
          fontSize: '1rem',
          color: '#555',
          margin: '12px 0',
          flex: '1',
      },
      linkContainer: {
          textAlign: 'center',
          marginTop: '12px',
      },
      link: {
          display: 'block',
          padding: '10px 20px',
          fontSize: '1rem',
          backgroundColor: THEMECOLOR,
          color: '#fff',
          borderRadius: '5px',
          textDecoration: 'none',
          margin: '12px 0',
      },
      linkHover: {
          backgroundColor: '#156',
      },
  };

  return (
    <div key={index} style={portfolioItemStyles.container}>
        <div style={portfolioItemStyles.imageContainer}>
            <img src={item.imageSrc} alt={item.title} style={portfolioItemStyles.image} />
        </div>
        <div style={portfolioItemStyles.contentContainer}>
            <h3 style={portfolioItemStyles.title}>{item.title}</h3>
            <p style={portfolioItemStyles.description}>{item.description}</p>
            <div style={portfolioItemStyles.linkContainer}>
                <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={portfolioItemStyles.link}
                >
                    Visit Project
                </a>
            </div>
        </div>
      {index < _length && <br />}
    </div>
  );
}

// function PortfolioItem({ item, index, _length }) {
//     // const itemStyle = {
//     //     position: 'relative',
//     //     height: '100vh',
//     //     // backgroundColor: 'rgba(90, 150, 180,0.8)',
//     //     // margin: '1px 2px'
//     // };

//     // const contentStyle = {
//     //     position: 'relative',
//     //     display: 'flex',
//     //     flexDirection: 'column',
//     //     alignItems: 'center',
//     //     justifyContent: 'space-evenly',
//     //     height: '100%',
//     //     color: 'white',
//     //     textAlign: 'center',
//     // };

//     // const titleStyle = {
//     //     fontSize: '24px',
//     //     fontWeight: '800'
//     // };

//     // const linkStyle = {
//     //     color: 'white',
//     //     textDecoration: 'none',
//     //     // marginTop: '8px',
//     //     fontSize: '16px',
//     // };

//     const portfolioItemStyles = {
//         subcontainer: {
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             padding: '20px',
//             backgroundColor: 'transparent',
//             textAlign: 'center',
//             // margin: '8px auto',
//             width: '100%',
//             margin: '0px 21px',
//             height: '80%',
//         },
//         container: {
//           display: 'flex',
//           alignItems: 'center',
//           padding: '20px',
//           borderRadius: '10px',
//           backgroundColor: '#fff',
//           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//           textAlign: 'center',
//         //   maxWidth: '300px',
//           margin: '33px auto 0px',
//         //   width: '80%',
//           minWidth: '60%',
//           maxWidth: '81%',
//           height: '33vh'
//         },
//         image: {
//           width: '100%',
//           maxWidth: '12vw',
//           height: 'auto',
//           margin: '15px',
//           borderRadius: '25%',
//           alignItems: 'center',
//           justifyContent: 'center',
//         //   border: '1px solid black'
//         },
//         title: {
//           fontSize: '2.1rem',
//           color: THEMECOLOR, // Theme color
//           margin: '15px',
//           fontWeight: '750',
//           letterSpacing: '0.5px'
//         },
//         description: {
//           fontSize: '1rem',
//           color: '#555',
//           margin: '15px',
//         //   maxWidth: '33%',
//           width: '100%'
//         },
//         link: {
//           display: 'inline-block',
//           padding: '10px 20px',
//           fontSize: '1rem',
//           backgroundColor: THEMECOLOR, // Theme color
//           color: '#fff',
//           borderRadius: '5px',
//           textDecoration: 'none',
//           margin: '15px',
//         },
//         linkHover: {
//           backgroundColor: '#156', // Darker color on hover
//         },
//       };

//     return (
//         <div key={index} style={{ display: 'flex', width: '100%'}}>
//             <div style={portfolioItemStyles.container}>
//                 <img src={item.imageSrc} alt={item.title} style={portfolioItemStyles.image} />
//                 <div style={portfolioItemStyles.subcontainer}>
//                     <h3 style={portfolioItemStyles.title}>{item.title}</h3>
//                     <p style={portfolioItemStyles.description}>{item.description}</p>
//                     <a
//                     href={item.link}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     style={portfolioItemStyles.link}
//                     >
//                     Visit Project
//                     </a>
//                 </div>
//             </div>

//             {index < _length && <br />}
//         </div>
//     );
// }

export default function Portfolio() {
    const portfolioData = [
        {
            imageSrc: require('../images/one28.png'),
            title: 'One28',
            description: "One28\nCTO / Lead Developer\nSwift, Stripe, Core Data, Firebase, NodeJS, Full-Stack & UI/UX",
            link: 'https://apps.apple.com/us/app/one28/id1435009217',
        },
        {
            imageSrc: require('../images/one28-dj.png'),
            title: 'One28 DJ',
            description: "One28 DJ\nCTO / Lead Developer\nSwift, Stripe, Core Data, Firebase, NodeJS, Full-Stack & UI/UX",
            link: 'https://apps.apple.com/us/app/one28-dj/id1457920734',
        }, {
            imageSrc: require('../images/dr.png'),
            title: 'DermaRite',
            description: "Core Data, Objective C, Swift, JSON.  Co-Developer. (Modified for client)",
            link: 'https://apps.apple.com/us/app/dermarite/id1056301580',
        }, {
            imageSrc: require('../images/az.png'),
            title: 'AudioZone',
            description: "Swift, Core Data, Objective C, Audio Routing, CarPlay / Bluetooth. Sole Developer.",
            link: 'https://apps.apple.com/us/app/audiozone/id1578123313',
        }, {
            imageSrc: require('../images/lj.png'),
            title: 'Lil Jump',
            description: "Swift, Cocoapods, Deep linking, Sprite Kit, In app purchases, Ad integration. Developed independently.",
            link: 'https://apps.apple.com/us/app/lil-jump/id1330237721',
        }, 
        // {
        //     imageSrc: require('../images/cog.png'),
        //     title: 'C.O.G.',
        //     description: "Swift, Lua, Corona, In app purchases, Ad integration, Cocoapods, Deep linking, Sprite Kit. Developed independently.",
        //     link: 'https://apps.apple.com/us/app/c-o-g/id1378463812',
        // }, 
        {
            imageSrc: require('../images/etd.jpg'),
            title: 'Escape The Desert',
            description: "Swift, Cocoapods, In app purchases, Ad integration. Developed independently.",
            link: 'https://apps.apple.com/us/app/escape-the-desert/id1507829342',
        }
    ];

    const portfolioListStyle = {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        // height: '100vh',
        // backgroundColor: 'rgba(90, 150, 180,0.8)',
        backgroundColor: THEMECOLOR,
        minHeight: '65vh',
        paddingBottom: "50px"
    };

    const titleStyle = {
      backgroundColor: THEMECOLOR,
      paddingTop: '16px',
      fontSize: '44px',
      fontWeight: '850',
      textAlign: 'center',
      color: 'white'
    }

    // const [focusedItem, setFocusedItem] = useState(require('../imgs/one28.png'));

    return (
        <PageWrapper themeColor={THEMECOLOR}>
            {/* <Background imageSrc={focusedItem}/> */}
            <h1 style={titleStyle}>Our Work</h1>
            <div style={portfolioListStyle}>
              {portfolioData.map((item, index) => (
                  <PortfolioItem key={index} item={item} index={index} _length={portfolioData.length - 1} />
              ))}
            </div>
            <hr />
        </PageWrapper>
      );

}