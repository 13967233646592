import { PageWrapper } from '../Wrappers/PageWrapper';

import { AboutSIT } from './AboutSIT';
import { AboutTeam } from './AboutTeam';

import TeamImage from "../../images/teamImage.jpeg"
// import SitImage from "../../images/innovation.avif"
import SitImage from "../../images/lightBulb2.avif"

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

const THEMECOLOR = "#882"

export default function About() {

    const borderInfo = "4px solid " + THEMECOLOR
    const boxShadowInfo = "5px 0px 10px 0px " + THEMECOLOR

    return (
        <PageWrapper themeColor={THEMECOLOR}>
            <Tabs 
                position="relative" 
                orientation="horizontal" 
                variant="unstyled" 
                isFitted
                // height="90vh"
                margin="10px"
            >
                <TabList>
                    <Tab 
                        fontSize="25px"
                        fontWeight="bold"
                        _selected={{ boxShadow: boxShadowInfo, }}
                        border={borderInfo}
                        borderRadius="10px"
                        margin="5px"
                        backgroundImage={SitImage}
                        backgroundSize="cover"
                        backgroundPosition="center"
                        color="white"
                    >SIT</Tab>
                    <Tab
                        fontSize="25px"
                        fontWeight="bold"
                        _selected={{ boxShadow: boxShadowInfo, }}
                        border={borderInfo}
                        borderRadius="10px"
                        margin="5px"
                        backgroundImage={TeamImage}
                        backgroundSize="cover"
                        backgroundPosition="center"
                        color="white"
                    >Our Team</Tab>
                </TabList>
                <TabPanels
                    // borderLeft="2px solid black"
                    // margin="10px"
                    overflow="auto"
                >
                    <TabPanel>
                        <AboutSIT themeColor={THEMECOLOR} />
                    </TabPanel>
                    <TabPanel>
                        <AboutTeam themeColor={THEMECOLOR}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <hr />
        </PageWrapper>
    )
}