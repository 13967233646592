import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageWrapper } from './Wrappers/PageWrapper';

const THEMECOLOR = "#7EC8E3"


export default function Homepage() {

    const { id } = useParams();
    
    const navigate = useNavigate();

    const styles = {
        background: {
            color: 'white',
            fontWeight: 700,
            justifyContent: 'center',
            paddingTop: '8px',
            margin: 'auto',
            // minHeight: '100%',
            overflow: 'hidden',
            alignItems: 'center',
        }
    }

    useEffect(() => {
        if (id !== '') navigate('/');
    },[id, navigate]);

    const handleLearnMoreButton = (e) => {
        navigate('/about');
    }

    return (
        <PageWrapper themeColor={THEMECOLOR}>
            <div style={styles.background}>
                <div style={{ height: "15%" }}>
                    <h1 style={{
                        fontSize: "5vw",
                        textAlign: "center",
                        color: "#7EC8E3",
                        margin: "10px"
                    }}>Create, Innovate & Transform</h1>
                </div>
                <div style={{ height: "70%", width: "100%", display: "flex", justifyContent: "center" }}>
                    <img alt='' src='https://unsplash.it/600/400' style={{
                        width: "70%",
                        height: "100%",
                        border: "5px solid #7EC8E3",
                        borderRadius: "10px"
                    }} />
                </div>
                <div style={{ 
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "12%"
                }}>
                    <Button 
                        size="lg" 
                        margin="10px" 
                        color="#7EC8E3" 
                        colorScheme='white' 
                        border="2px solid #7EC8E3"
                        onClick={handleLearnMoreButton}
                    >
                        Learn More
                    </Button>
                </div>
            </div>
        </PageWrapper>
    )
}